<script>
import Layout from "../../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import { mapActions, mapState, mapGetters } from 'vuex';

export default {
    data() {
        return {
            days: {
                mon: {
                    slot1: {
                        startTime: '',
                        endTime: ''
                    },
                    slot2: {
                        startTime: '',
                        endTime: ''
                    },
                    slot3: {
                        startTime: '',
                        endTime: ''
                    }
                },
                tue: {
                    slot1: {
                        startTime: '',
                        endTime: ''
                    },
                    slot2: {
                        startTime: '',
                        endTime: ''
                    },
                    slot3: {
                        startTime: '',
                        endTime: ''
                    }
                },
                wed: {
                    slot1: {
                        startTime: '',
                        endTime: ''
                    },
                    slot2: {
                        startTime: '',
                        endTime: ''
                    },
                    slot3: {
                        startTime: '',
                        endTime: ''
                    }
                },
                thu: {
                    slot1: {
                        startTime: '',
                        endTime: ''
                    },
                    slot2: {
                        startTime: '',
                        endTime: ''
                    },
                    slot3: {
                        startTime: '',
                        endTime: ''
                    }
                },
                fri: {
                    slot1: {
                        startTime: '',
                        endTime: ''
                    },
                    slot2: {
                        startTime: '',
                        endTime: ''
                    },
                    slot3: {
                        startTime: '',
                        endTime: ''
                    }
                },
                sat: {
                    slot1: {
                        startTime: '',
                        endTime: ''
                    },
                    slot2: {
                        startTime: '',
                        endTime: ''
                    },
                    slot3: {
                        startTime: '',
                        endTime: ''
                    }
                },
                sun: {
                    slot1: {
                        startTime: '',
                        endTime: ''
                    },
                    slot2: {
                        startTime: '',
                        endTime: ''
                    },
                    slot3: {
                        startTime: '',
                        endTime: ''
                    }
                }
            },
            modelVal: '',
            exe: [],
            loder : false,
            btnDisable : false,
        };
    },
    components: {
        Layout,
        PageHeader,
    },
    computed: {
        ...mapState('auth', {
            userData: state => state.user,
        }),

        ...mapGetters({
            getAvailability: "availability/getAvailability",
        }),
    },
    methods: {
        ...mapActions({
            setUserAvailability: "availability/setUserAvailability",
            getUserAvailability: "availability/getUserAvailability",
        }),
        claerRow(dayName) {
            [...Object.entries(this.days[dayName])].map(
                ([curr]) => {
                    this.days[dayName][curr].startTime = 0
                    this.days[dayName][curr].endTime = 0
                }
            )
        },
        saveData() {
            this.loder = true
            this.btnDisable = true
            let availabilities = [];
            for (const iterator in this.days) {
                let singleDays = this.days[iterator]
                let payload = {
                    day: iterator,
                    slot1_start_time: singleDays.slot1.startTime,
                    slot1_end_time: singleDays.slot1.endTime,
                    slot2_start_time: singleDays.slot2.startTime,
                    slot2_end_time: singleDays.slot2.endTime,
                    slot3_start_time: singleDays.slot3.startTime,
                    slot3_end_time: singleDays.slot3.endTime
                }
                availabilities.push(payload)
            }
            let payload = {
                availabilities: availabilities,
                user_id: this.userData.user_details.user_id,
            }

            this.setUserAvailability(payload)
                .then(({data}) => {
                    this.$toast.open({
                        message: data.message,
                        type: "success",
                    })
                    this.loder = false
                    this.btnDisable = false
                }
                ).catch(() => {
                    this.$toast.open({
                        message: "Network error",
                        type: "success",
                    })
                    this.loder = false
                    this.btnDisable = false
                })
        },
        setData(data) {

            data.map(
                (curr) => {
                    this.days[curr.day].slot1.startTime = curr.slot1_start_time
                    this.days[curr.day].slot1.endTime = curr.slot1_end_time

                    this.days[curr.day].slot2.startTime = curr.slot2_start_time
                    this.days[curr.day].slot2.endTime = curr.slot2_end_time

                    this.days[curr.day].slot3.startTime = curr.slot3_start_time
                    this.days[curr.day].slot3.endTime = curr.slot3_end_time
                }
            )
        }
    },
    mounted() {
        this.getUserAvailability(this.userData.user_details.user_id)
            .then(res => this.setData(res.data.data.availabilities))

    }
}
</script>

<template>
    <Layout>
        <PageHeader />


        <div class="card mt-4">
            <div class="card-header align-items-center d-flex">
                <h4 class="card-title mb-0 flex-grow-1">Availability</h4>
                <div class="flex-shrink-0">
                    <button class="d-flex align-items-center btn btn-primary waves-effect waves-light" @click="saveData" :disabled="btnDisable">
                        <p class="mb-0">Update</p>
                        <div class="spinner-border loader-setup" role="status" v-if="loder">
                            <span class="sr-only">{{ $t('t-loading') }}</span>
                        </div>
                    </button>
                </div>
            </div>


            <div class="card-body">

                <div class="table-responsive table-card">
                    <table class="table mb-0 align-middle table-bordered table-nowrap">
                        <thead class="table-light">
                            <tr>
                                <th scope="col"></th>
                                <th scope="col" class="text-center">Slot 1</th>
                                <th scope="col" class="text-center">Slot 2</th>
                                <th scope="col" class="text-center">Slot 3</th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(singleDay, index) in Object.entries(days)" :key="index">
                                <td scope="row" class="text-capitalize fs-5"> <b> {{ singleDay[0] }} </b> </td>
                                <td>
                                    <div class="d-flex px-4">
                                        <input type="time" class="form-control me-1"
                                            v-model="days[singleDay[0]].slot1.startTime">
                                        <input type="time" class="form-control" v-model="days[singleDay[0]].slot1.endTime">
                                    </div>
                                </td>
                                <td>
                                    <div class="d-flex px-4">
                                        <input type="time" class="form-control me-1"
                                            v-model="days[singleDay[0]].slot2.startTime">
                                        <input type="time" class="form-control" v-model="days[singleDay[0]].slot2.endTime">
                                    </div>
                                </td>
                                <td>
                                    <div class="d-flex px-4">
                                        <input type="time" class="form-control me-1"
                                            v-model="days[singleDay[0]].slot3.startTime">
                                        <input type="time" class="form-control" v-model="days[singleDay[0]].slot3.endTime">
                                    </div>
                                </td>

                                <td scope="row" class="d-flex justify-content-center align-items-center">
                                    <button class="btn btn-primary waves-effect waves-light"
                                        @click="claerRow(singleDay[0])">Clear</button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </Layout>
</template>